import styles from './Home.module.css'

export default function Home(){
    return(
        <div className={styles.section1}>
            <div className='container'>
               <h1>COMING SOON</h1>
               </div>
        </div>
    )
}